import { asyncWrap, safeFetchAPI } from '@/api';
import { userUtils, utils } from '@/helpers';
import React from 'react';

export default function useFeatureFlagEnabled(flag: string) {
	const ALWAYS_ENABLED = userUtils.isAdmin() && userUtils.isExperimental();
	const [enabled, setEnabled] = React.useState(ALWAYS_ENABLED);

	React.useEffect(() => {
		asyncWrap(async () => {
			if (ALWAYS_ENABLED) {
				setEnabled(true);
				return;
			}
			const [enabled, err] = await safeFetchAPI(
				`https://posthog.alpineiq.com/posthog/feature/${flag}?distinctID=${utils.auth.getGlobalID() || utils.auth.getEmail()}`,
			);
			if (err) {
				console.error(err);
			} else {
				setEnabled(enabled);
			}
		});
	}, [flag]);

	return enabled;
}
