import React from 'react';

import utils from '../helpers/utils';
import { adminUtils, userUtils } from '@/helpers';

export default function updateHS(user: any) {
	const _hsq = (window as any)._hsq || [];

	if (location.pathname.toLowerCase().includes('walletsearch')) {
		return;
	}

	const bannedUIDS = ['1', '0'];

	if (!_hsq || !user || !user.id || bannedUIDS.includes(user.uid)) return;

	_hsq.push([
		'identify',
		{
			email: utils.auth.getEmail(),
			company_name: user?.profile?.name,
			uid: user.id,
		},
	]);

	_hsq.push(['trackPageView']);
}

const invalidPaths = [
	'/swagger',
	'/join/c',
	// '/walletsearch'
];

export function canLoadHubspot() {
	const isAdmin = userUtils.isAdmin();
	const isWhitelisted = adminUtils.hubspotWhitelist();

	// Some pages should never have the HS script loaded
	if (invalidPaths.some((path) => location.pathname.toLowerCase().includes(path))) {
		return false;
	}

	return !isAdmin || isWhitelisted;
}
