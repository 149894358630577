import React from 'react';
import { utils } from '../helpers';

type GetConfig = {
	defaultValue: string;
	validatorArray: string[];
};

type UseQueryReturn = {
	get: (key: string, config?: GetConfig) => string | null;
	set: (key: string, value: string) => void;
	lastUpdated: number;
	lastValue: string | null;
};

export default function useQuery(opts?: { key?: string; popState?: boolean; config?: GetConfig }): UseQueryReturn {
	const [lastUpdated, setLastUpdated] = React.useState(Date.now());
	const [lastValue, setLastValue] = React.useState<string | null>(null);
	const [query, setQuery] = React.useState(new URLSearchParams(window.location.search));

	const updateFromURL = (url = new URL(window.location.href)) => {
		const searchParams = new URLSearchParams(url.search);
		setQuery(searchParams);
		if (opts?.key) {
			setLastValue(searchParams.get(opts.key) ?? null);
		}
		setLastUpdated(Date.now());
	};

	React.useEffect(() => {
		updateFromURL();
		if (opts?.popState !== false) {
			window.addEventListener('popstate', () => updateFromURL());
		}

		return () => {
			window.removeEventListener('popstate', () => updateFromURL());
		};
	}, []);

	function get<T = string>(key: string, config?: GetConfig): T | null {
		const value = query.get(key);
		if (config?.defaultValue) {
			const isValid = config?.validatorArray?.map((str) => utils.strify(str))?.includes(utils.strify(value) || '');
			if (!isValid) {
				return config.defaultValue as T;
			}
		}
		return value as T;
	}

	const set = (key: string, value: any) => {
		const url = new URL(window.location.href);
		if (!value) url.searchParams.delete(key);
		else url.searchParams.set(key, value);
		window.history.pushState({}, '', url.toString());
		updateFromURL(url);
	};

	return { get, set, lastUpdated, lastValue };
}
